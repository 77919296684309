.component-image {
	.component-alignment-stretch & {
		height: 100%;
		overflow: hidden;

		.content-wrapper {
			height: 100%;

			img {
				height: 100%;
				width: auto;
			
				.mobile-image & {
					position: relative;
				    display: block;
				    right: 104%;

				    @include mediaquery(tablet-portrait) {
				    	right: 62%;
				    }

				    @include mediaquery(tablet-landscape) {
				    	right: auto;
				    }
				}
			}
		}
	}

	.xl-hero & {
		.content-wrapper {
			height: 100vh;
			overflow: hidden;
			position: relative;

			@include mediaquery(desktop) {
				height: 1200px;
			}
		}
	}
}