// Form global styles
form {
	width: 100%;
	margin: 0 auto;
	position: relative;
	@include clearRow;

	br {
		display: none;
	}
}

.form-left, .form-right {
	@include mediaquery(tablet-landscape) {
		width: calc(50% - 16px);
		float: left;
	}
}

.form-left {
	@include mediaquery(tablet-landscape) {
		margin-right: 32px;
	}
}

input, .select, textarea {
	background-color: $white;
	border: 2px solid color('global', 'black');
	border-radius: 0;
	color: color('global', 'black');
	display: block;
	font-size: 15px;
	line-height: 1;
	padding: 5px $gap*4 0;
	text-align: left;
	margin-bottom: $gap*3;
	width: 100%;
	height: 52px;
	outline: none;
	@include sans-serif-medium
	@include placeholder(rgba(0, 0, 0, .5));
}

input[type="submit"] {
	border: 0;
	background-color: color('global', 'black');
	box-sizing: border-box;
	clear:both;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	height: 80px;
	letter-spacing: .5px;
	line-height: 1.5;
	margin: 20px auto 0;
	padding: 7px 80px 0;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
	overflow: hidden;
	@include sans-serif-black;
	@include transition(background-color .4s ease-out);

	@include mediaquery(desktop) {
		&:hover {
			background-color: $accentColor;
		}
	}

}

label {
	@extend .sans-serif14;
	color: white;
	letter-spacing: 0;
	clear:both;
	display: block;
	text-align: left;
	margin-bottom: 10px;

	span {
		display: inline-block;
		font: inherit;
	}
}

textarea {
	padding-top: $gap*4;
	height: 180px;
	resize: none;
}

.wpcf7-checkbox {

	@include mediaquery(tablet-portrait) {
		width: 50%;
	}

	.wpcf7-list-item-label {
		cursor: pointer;
		display: block;
		padding-left: $gap*2;
		position: relative;
		line-height: 1.4;
		@include sans-serif-medium;


		&:before {
			border-radius: 50%;
			border: 2px solid white;
			content: '';
			cursor: pointer;
			display: inline-block;
			height: $gap;
			left: 0;
			position: absolute;
			top: calc(50% - 15px);
			width: $gap;
		}

		@include mediaquery(desktop) {
			&:hover:before {
				background-color: rgba(255, 255, 255, .5);
			}
		}
	}

	input[type="checkbox"] {
		display: none;

		&:checked + .wpcf7-list-item-label:before {
			background-color: white;
		}
	}

}

.newsletter {
	@include mediaquery(tablet-portrait) {
		width: 50%;
	}
}

.contact-footer {
	text-align: center;

	@include mediaquery(tablet-portrait) {
		@include flexbox(flex);
		@include flexbox-align-items(center);
		@include flexbox-justify-content(space-between);
	}
}

.screen-reader-response {
	display: none;
}

.select {
	-webkit-appearance: none;
	background: transparent;
	border-top: 1px solid white;
	padding: 4px 0 0 0;
	position: relative;
	text-transform: uppercase;

	&:after {
		border-color: color('global', 'dark-grey') transparent;
		border-style: solid;
		border-width: 8px 6px 0;
		content: '';
		height: 0;
		margin-top: -4px;
		position: absolute;
		right: 40px;
		top: 50%;
		width: 0;
		z-index: 1;
	}

	select {
			border: none;
		cursor: pointer;
		background-color: transparent;
		height: 47px;
		outline: none;
			padding: 0 20px;
		position: relative;
		text-transform: uppercase;
		width: 100%;
		z-index: 2;
		-webkit-appearance: none;
		-moz-appearance: none;

		option {
			color: black;
		}
	}

	select::-ms-expand {
		display: none;
	}
}

.subscribe-error {
	margin: $gap*.5 0;
}

.ajax-loader {
	display: none;
}

.wpcf7-form-control-wrap {
	display: block;
	margin-bottom: 0;
	position: relative;

}

.wpcf7.hideForm {
	display: none;
}

.component-contact-thankyou {
	display: none;
	text-align: center;

	&.showThankyou {
		display: block;
	}

	.social-wrapper {
		margin: 0 35px $gap*5;
		display: inline-block;
		font-size: 16px;
		@include sans-serif-black;

		@include mediaquery(tablet-portrait) {
			margin: 0 50px;
		}
	}

	.social {
		display: block;
		margin-bottom: $gap*3;
	}
}

.wpcf7-not-valid-tip {
	color: red;
	display: block;
	font-style: italic;
	margin-top: $gap * .25;
	opacity: 1;
	text-transform: none;

	.deadline &, .budget & {
		position: absolute;
		top: 10px;
	}

	.row-text-white & {
		color: red;
	}
}

.wpcf7-response-output {
	font-size: 20px;
	color: white;
	clear: both;
	margin-top: $gap*2;
}
