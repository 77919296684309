.component-grid {
	@include mediaquery(tablet-portrait) {
		@include flexbox(flex);
		@include flexbox-align-items(stretch);
	}

	&.flex-left {
		@include mediaquery-max(tablet-portrait) {
			@include flexbox(flex);
			@include flexbox-direction(column-reverse);
			@include flexbox-wrap(wrap);
		}
	}

	.grid-link {
		position: absolute;
	    left: 0;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    z-index: 1;
	}

	.headline2 {
		@include mediaquery(tablet-landscape) {
			font-size: 28px;
		}

		@include mediaquery(desktop) {
			font-size: 36px;
		}
	}

	.bottom-border.bottom-border-small {
		@include mediaquery(tablet-portrait) {
			padding-bottom: 4px;
    		margin-bottom: 12px;
		}

		@include mediaquery(desktop) {
			padding-bottom: 13px;
   			margin-bottom: 20px;
		}
	}

	.paragraph-large {
		font-size: 16px;
		margin-bottom: $gap*7.5;

		@include mediaquery(desktop) {
			font-size: 20px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	li {
		list-style-type: none;
		position: relative;
		padding-left: 31px;
		font-size: 16px;
		margin-bottom: $gap*5;
		line-height: 1.5;
		@include sans-serif-medium;

		@include mediaquery(tablet-landscape) {
			font-size: 16px;
		}

		@include mediaquery(desktop) {
			font-size: 16px;	
		}

		&:before {
			content: '';
			position: absolute;
			top: 6px;
			left: 0;
			width: 7px;
			height: 7px;
			border: 2px solid $white;
			border-radius: 50%;
		}
	}

	.grid-image {
		height: 0;
		padding-bottom: 76.3%;
		width: 100%;
		position: relative;
		overflow: hidden;

		@include mediaquery(tablet-portrait) {
			height: 0;
			padding-bottom: 57.3%;
		}

		.grid-slide {
			height: 100%;
			position: relative;
		}

		img, .iframe-wrapper {
			min-height: 200px;
			height: auto;
			width: 100%;
			position: absolute;
			left: -50%;
			right: -50%;
			top: 0;
			margin: 0 auto;
			opacity: 1;
			cursor: pointer;

			@include mediaquery(tablet-portrait) {
				width: auto;
				height: 100%;
				min-width: 100%;

				&.faded-in {
					transition-delay: .2s;
				}
			}

			&.activeImage.faded-in {
				opacity: 1;
				z-index: 1;
			}

		}

		.iframe-wrapper {
			background-color: color('global', 'black');
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;

			iframe {
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 100%;
				height: 73%;
				top: 13.5%;
			}
		}
	}

	.slider-arrow {
		background-color: transparent;
		
		&:after {

			@include mediaquery(tablet-landscape) {
				width: 24px;
				height: 20px;
			}
		}

	}

	.slick-list {
		position: absolute;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;
	}

	.slick-track {
		height: 100%;
	}

	.slick-slide {
		overflow: hidden;
	}

	.slick-slide > div {
		height: 100%;
	}

	.slick-dots {
		bottom: 10px;
		left: 0;
		margin:0 auto;
		padding: 0 20px;
		position: absolute;
		right: 0;
		text-align: center;
		z-index: 10;

		li {
			cursor: pointer;
			display: inline-block;
			height: 15px;
			margin:0 10px;
			width: 15px;

			&:before {
				display: none;
			}

			button {
				background-color: transparent;
				border: 1px solid white;
				border-radius: 50%;
				cursor: pointer;
				display: block;
				height: 10px;
				width: 10px;
				overflow: hidden;
				text-indent: -99999em;
				padding: 0;
			}

			&.slick-active {

				button {
					background-color: white;
				}
			}
		}
	}

	.grid-text {
		position: relative;

		&:after {
			background-color: color('global', 'black');
			position: absolute;
			content: '';
			top: 0;
			left: -100%;
			height: 100%;
			width: 100%;
			z-index: -1;
			@include transition(background-color .4s ease-out, left .6s ease-out);
		}


		@include mediaquery(tablet-portrait) {
			height: 100%;
			overflow: hidden;
			width: 100%;
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-justify-content(flex-start);
		}

		&.fade-me-in {
			opacity: 1;
		}

		&.faded-in:after {
			left: 0;
		}

		.grid-text-inner {
			padding: $gap*5 $gap*5;
			width: 100%;

			@include mediaquery(tablet-landscape) {
				width: 85%;
				padding: $gap*5 $gap*12.5;
			}

			@include mediaquery(xl) {
				width: 475px;
			}
		}
	}



	.grid-item {
		border-bottom: 5px solid white;
		overflow: hidden;
		position: relative;
		width: 100%;

		@include mediaquery(tablet-portrait) {
			width: 55.9%;
		}

		.grid-image {
			@include mediaquery(tablet-portrait) {
				padding-bottom: 88.6%;
			}

			@include mediaquery(tablet-landscape) {
				padding-bottom: 76.6%;
			}		}
	}

	.grid-text-right {

		@include mediaquery(tablet-portrait) {
			border-left: 5px solid white;
			width: 44.86%;
		}
	} 

	.grid-text-left {
		@include mediaquery(tablet-portrait) {
			border-right: 5px solid white;
			width: 44.86%;
		}
		
		.grid-text {
			@include mediaquery(tablet-portrait) {
				@include flexbox-justify-content(flex-end);
			}
		}
	} 

}