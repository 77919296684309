//global padding settings
.padding-top {
	padding-top: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*20;
	}
}

.padding-right {
	padding-right: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-right: $gap*20;
	}
}

.padding-bottom {
	padding-bottom: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*20;
	}
}

.padding-left {
	padding-left: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-left: $gap*20;
	}
}


.padding-top-small {
	padding-top: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*15;
	}
}

.padding-right-small {
	padding-right: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-right: $gap*15;
	}
}

.padding-bottom-small {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*15;
	}
}

.padding-left-small {
	padding-left: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-left: $gap*15;
	}
}

//mobile and tablet-portrait only
.padding-top-small-tablet {
	padding-top: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-top: 0;
	}
}

.padding-right-small-tablet {
	padding-right: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-right: 0;
	}
}

.padding-bottom-small-tablet {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: 0;
	}
}

.padding-left-small-tablet {
	padding-left: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-left: 0;
	}
}

.padding-right-tiny {
	padding-right: $gap*2.5;

	@include mediaquery(tablet-portrait) {
		padding-right: $gap*7.5;
	}
}

.padding-left-tiny {
	padding-left: 0;

	@include mediaquery(tablet-portrait) {
		padding-left: $gap*7.5;
	}
}

.padding-bottom-tiny {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*5;
	}
}

.padding-top-20-40 {
	padding-top: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*10;
	}
}

.padding-top-20-60 {
	padding-top: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*15;
	}
}

.padding-top-20-80 {
	padding-top: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*20;
	}
}

.padding-top-40-20 {
	padding-top: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*5;
	}
}

.padding-top-40-80 {
	padding-top: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*20;
	}
}

.padding-top-80-120 {
	padding-top: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-top: $gap*30;
	}
}

.padding-top-40 {
	padding-top: $gap*10;
}

.padding-top-60 {
	padding-top: $gap*15;
}

.padding-bottom-20-0 {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: 0;
	}
}

.padding-bottom-20-40 {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*10;
	}
}

.padding-bottom-20-60 {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*15;
	}
}

.padding-bottom-20-80 {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*20;
	}
}

.padding-bottom-40-20 {
	padding-bottom: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*5;
	}
}

.padding-bottom-40-80 {
	padding-bottom: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*20;
	}
}

.padding-bottom-80-120{
	padding-bottom: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*30;
	}
}

.padding-bottom-40-0 {
	padding-bottom: $gap*10;

	@include mediaquery(tablet-portrait) {
		padding-bottom: 0;
	}
}

.padding-bottom-12-40 {
	padding-bottom: $gap*3;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*10;
	}
}

.padding-bottom-12-20 {
	padding-bottom: $gap*3;

	@include mediaquery(tablet-portrait) {
		padding-bottom: $gap*5;
	}
}


.padding-bottom-20-0 {
	padding-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: 0;
	}
}

.padding-bottom-40 {
	padding-bottom: $gap*10;
}

.padding-bottom-60 {
	padding-bottom: $gap*15;
}

.padding-left-0-12 {
	@include mediaquery(tablet-portrait) {
		padding-left: $gap*3;
	}
}

.padding-left-12 {
	padding-left: $gap*3;
}

.padding-right-0-12 {
	@include mediaquery(tablet-portrait) {
		padding-right: $gap*3;
	}
}

.padding-right-12 {
	padding-right: $gap*3;
}

//global margins 

.bottom-negative-margin {
	margin-bottom: -100px;
}

.no-margin-bottom-mobile, .mobile-hidden, .tablet-hidden, .desktop-hidden {
	margin-bottom: 0;
	padding: 0;

	&.padding-bottom,
	&.padding-top,
	&.padding-right,
	&.padding-left,
	&.padding-bottom-small,
	&.padding-top-small,
	&.padding-right-small,
	&.padding-left-small,
	&.padding-bottom-small-tablet,
	&.padding-top-small-tablet,
	&.padding-right-small-tablet,
	&.padding-left-small-tablet {
		padding: 0;
	}
}

.mobile-hidden {
	@include mediaquery-max(tablet-portrait) {
		display: none;
	}

	@include mediaquery(tablet-portrait) {
		display: inline-block;
	}
}

.tablet-hidden {
	@include mediaquery-max(tablet-portrait) {
		display: inline-block;
	}

	@include mediaquery-max(desktop) {
		display: none;
	}

	@include mediaquery(desktop) {
		display: inline-block;
	}
}

.desktop-hidden {

	@include mediaquery(desktop) {
		display: none;
	}

	&.tablet-hidden {
		@include mediaquery-max(tablet-portrait) {
			display: inline-block;
		}
	}
}

// NOTE: (DP) Double class specificity #hack to override other
// layout tool based padding config settings
.no-top-padding-on-mobile.no-top-padding-on-mobile {
	@include mediaquery-max(tablet-portrait) {
		padding-top: 0;
	}
}
