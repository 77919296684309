* {
	// background: rgba(255, 0, 0, .1);
}

// FADE ME IN - Prevents flashing for fade me in transition
// (js adds opacity 1 once loaded)
*:not(.main-header), *:not(.page-footer) {
	h1, h2, h3, h4, h5, p {
		opacity: 0;

		&.dont-fade-me-in {
			opacity: initial;
		}
	}
}

body {
	font-size: px-to-rem(18px);
	height: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	@include sans-serif-medium;

	@include mediaquery(tablet-landscape) {
		background-size: 100% auto;

		&.navOpen {
			left: auto;
			position: relative;
			right: auto;
			top: auto;
		}
	}

	&.popupOpen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

}

.content-container {
	min-height: calc(100vh - 150px);
	padding-top: 96px;
	@include transition(padding-top .2s ease-out);

	@include mediaquery(tablet-landscape) {
		padding-top: 277px;
	}

	.hideNav & {
		padding-top: 0;

		@include mediaquery(tablet-landscape) {
			padding-top: 63px;
		}
	}

}

.pure-g {
	letter-spacing: inherit;
	@include flexbox-align-content(inherit);
}

img {
	height: auto;
	width: 100%;
}

main.content {
	width: 100%;
}

//===
// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//	`contenteditable` attribute is included anywhere else in the document.
//	Otherwise it causes space to appear at the top and bottom of elements
//	that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//	`:before` to contain the top-margins of child elements.
//
.clearfix:before,
.clearfix:after {
	content: " "; // 1
	display: table; // 2
}

.clearfix:after {
	clear: both;
}

.center {
	margin: 0 auto;
}

.component {
	height: 100%;
	width: 100%;
}

.component-alignment-bottom {
	@include flexbox(flex);
	@include flexbox-align-items(flex-end);
}

.component-alignment-center {
	@include flexbox(flex);
	@include flexbox-align-items(center);
}

.component-alignment-stretch {
	@include flexbox(flex);
	@include flexbox-align-items(stretch);
}

.component-alignment-top {
	@include flexbox(flex);
	@include flexbox-align-items(flex-start);
}

.component-row,
.component-row-inner {
	background-position: center center;
	background-repeat: no-repeat;
	height: auto;
	position: relative;
	width: 100%;
	height: 100%;
}

.component-row {
	@include clearRow;
	z-index: 2;

	@media screen and (max-width: 767px) {
		// reverse media query to avoid extra css
		.row-reverse-mobile {
			@include flexbox-direction(column-reverse);
			@include flexbox(flex);
			&:after {
				display: none;
			}
		}
	}

	&.zindexTop {
		z-index: 100000;
	}
}

.component-row-standard {
	margin: 0 auto;
	max-width: $tablet-landscape-breakpoint;
	position: relative;
	width: 100%;
	padding: 0 $gap*5;

	@include mediaquery(desktop) {
		padding: 0;
	}
}


.content-wrapper {
	position: relative;
}

.foreground {
	z-index: 1000;
}

//===
// Hide from both screenreaders and browsers: h5bp.com/u
//
.fade-me-in {
	opacity: 0;
	@include transition(opacity 1s);

	&.faded-in {
		opacity: 1;
		@include transition(opacity 1s);
	}
}

.fill-image {
	left: 0;
	object-fit: cover;
	position: absolute;
	right: 0;
	top: 0;
}

.gallery img {
	// Overrides wysiwyg gallery border. cannot remove all the
	// styles programmatically without causing layout issues.
	border: 0 !important;
}

.hideOnMobile {
	display: none;

	@include mediaquery(tablet-portrait) {
		display: initial;
	}
}

.hideOnTabletLandscape {
	display: block;

	@include mediaquery(tablet-landscape) {
		display: none;
	}
}

.hideOnTabletPortrait {
	display: block;

	@include mediaquery(tablet-portrait) {
		display: none;
	}
}

.nav-hidden {
	display: none !important;

	@include nav-break {
		display: inherit !important;
	}
}

// NOTE: (DP) Specificity hack
.hideOnDesktop.hideOnDesktop.hideOnDesktop {
	display: block;

	@include mediaquery(desktop) {
		display: none;
	}
}

.hidden {
	display: none !important;
	visibility: hidden;
}


//===
// Hide visually and from screenreaders, but maintain layout
//
.invisible {
	visibility: hidden;
}

//===
// Image replacement, for logos and such
//
.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	// IE 6/7 fallback
	//text-indent: -9999px;

	&:before {
		content: '';
		display: block;
		height: 150%;
		width: 0;
	}
}

.last-row {
	padding-bottom: $gap*4;
}



// NOTE: (DP) Here be dragons. Thou art forewarned
.modal-blackout {
	background: black;
	bottom: 0;
	display: none;
	left: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;
	@include transition(opacity .5s);

	&.visible {
		opacity: 1;
		@include transition(opacity .5s);
	}

	.show-blackout-modal & {
		display: block;
	}

	// .page-legal & {
		display: block;
		opacity: 1;
	// }

	.dom-loaded & {
		opacity: 0;
	}

	.dom-loaded.dom-has-been-loaded-for-two-seconds & {
		display: none;
	}

	// .window-loaded.window-has-been-loaded-for-two-seconds.show-blackout-modal & {
	.dom-loaded.dom-has-been-loaded-for-two-seconds.show-blackout-modal & {
		display: block;

		&.visible {
			opacity: 1;
		}
	}
}

//background video 
.waves-background {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: -1;
}

.waves-placeholder {
	background-size: cover;
	bottom: 0;
	display: block;
	left: 0;
	opacity: 1;

	// !important needed to break out of .intro container
	position: fixed !important;

	right: 0;
	top: 0;

	// !important needed to win against .component-hero-animation>div
	z-index: 0 !important;

	@include transition(opacity 2s);

	.window-loaded & {
		opacity: 0;
	}

	.window-loaded.window-has-been-loaded-for-two-seconds & {
		display: none;
	}
}

.water-video-component {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;

	.video-landscape & {
		width: 100%;
	}

	.video-portrait & {
		height: 100%;
	}
}

//interactions
.new-elements {
	//for sliding in ajax loaded content
	opacity: 0;
	@include transition(opacity .3s ease-in);
}

.parallax-me {
	@include transform(translateZ(0));
}




.responsive-image {
	display: block;
	height: auto;
	left: -50%;
	margin: 0 auto;
	min-height: 100%;
	min-width: 100%;
	position: absolute;
	right: -50%;
	top: 0;
	width: auto;
	z-index: 1;
}

// set padding-bottom in individual style sheet as appropriate
.responsive-wrapper {
	height: 0;
	overflow: hidden;
	position: relative;
}

.right-border {
	border-right: 1px solid color('global', 'dark-border');
}

// Add this class to a row and it will add a
// dark BG that goes halfway down.
.row-half-backdrop {
	position: relative;

	&:before {
		content: " ";
		display: block;
		background-color: color('ground', 'navy');
		height: 50%;
		position: absolute;
		width: 100%;
	}
}

.showOnDesktop {
	display: none;

	@include mediaquery(desktop) {
		display: block;
	}
}

.showOnTabletLandscape {
	display: none;

	@include mediaquery(tablet-landscape) {
		display: block;
	}
}

// Image alignment for single posts
.single {
	.post-img {
		margin-bottom: $gap*3;
		max-width: 100%;
		width: auto;

		@include mediaquery(tablet-portrait) {
			margin-left: -10%;
			max-width: 120%;
			width: 120%;
		}

		&.alignleft, &.post-img-left {
			clear: left;
			float: left;
			margin-right: $gap*.5;
			max-width: 100%;
			width: auto;
		}

		&.alignright, &.post-img-right {
			clear: right;
			float: right;
			margin-left: $gap*.5;
			margin-right: -10%;
			max-width: 100%;
			width: auto;
		}
	}
}

// Apply to a row or column, and makes the component
// overlap whatever is above it by 100px;
.slide-up-component {
	.component {
		margin-top: -100px;
	}
}

.slideIn {
	-webkit-animation: moveUp 0.65s ease forwards;
	animation: moveUp 0.65s ease forwards;
	opacity: 1;
	@include transform(translateY(200px));
}



.svg-sprite {
	height: 0;
	overflow: hidden;
}


.top-border {
	border-top: 1px solid white;
	padding: $gap $gap 0 $gap;
}

.top-negative-margin {
	margin-top: -170px;
}

.video-popup {
	background-color: rgba(color('global', 'black'), .8);
	display: none;
	height: 100%;
	left: 0;
	padding: $gap * 3 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10000;

	@include mediaquery(tablet-landscape) {
		padding: $gap * 5 20%;
	}

	@include mediaquery-max(desktop) {
		padding: $gap * 6 0;
	}

	&.playVideo {
		display: block;

		.close {
			display: block;
			right: $gap;
			top: $gap;
			z-index: 10;

			@include mediaquery(desktop) {
				right: 19%;
				top: 70px;
			}

			&:before, &:after {
				background-color: $white;
			}

			@include mediaquery-max(desktop) {
				top: $gap * 5;
				left: 5%;
				right: initial;
			}
		}
	}
}

.video-wrapper,
.embed-youtube {
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;

	iframe, video {
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
}

//===
// Hide only visually, but have it available for screenreaders: h5bp.com/v
//
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

//===
// Extends the .visuallyhidden class to allow the element to be focusable
// when navigated to via the keyboard: h5bp.com/p
//
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.z-index-3 {
	z-index: 3;
}

#lottie {
	display: block;
	height: 100%;
	margin-bottom: 0;
	opacity: 1;
	overflow: hidden;
	transform: translate3d(0,0,0);
	text-align: center;
	width: 100%;

	@include mediaquery(tablet-portrait) {
		margin-bottom: $gap*1.5;
	}

	@include mediaquery(desktop) {
		margin-bottom: $gap*2.25;

		.short-screen & {
			margin-bottom: $gap*1.5;
		}
	}
}

@-webkit-keyframes moveUp {
	0% {}

	100% {
		-webkit-transform: translateY(0);
		opacity: 1;
	}
}

@keyframes moveUp {
	0% {}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@include keyframes(compassSway) {
	0% {
		@include transform(rotate(5deg));
	}

	50% {
		@include transform(rotate(-5deg));
	}

	100% {
		@include transform(rotate(0deg));
	}
}



@include keyframes(peek) {
	0% {
		@include transform(translateX(0));
	}

	50% {
		@include transform(translateX(1%));
	}

	100% {
		@include transform(translateX(0));
	}
}



.page-cover-modal {
	position: fixed;
	background-color: white;
	bottom: 0;
	height: 100vh;
	left: 0;
	opacity: 1;
	right: 0;
	top: 0vh;
	width: 100%;
	z-index: 10010;
	@include transition(z-index 1s ease-out, opacity 1s ease-out, background-color 1s ease-out);
	transition-delay: 1s;


	//once dom is loaded, remove modal
	.dom-loaded & {
		z-index: -1;
		opacity: 0;
	}

	//make background blue for homepage
	.home & {
		background-color: color('global', 'navy');
	}

	//display modal after nav is closed
	.leaveNav & {
		z-index: 10010;
		opacity: 1;
	}

	//make background white when leaving homepage
	.leaveNav.home & {
		background-color: white;
	}

}


// Column paddings for phone
@include mediaquery-max(phone) {
	.padding-top-phone {
		padding-top: 80px;
	}

	.padding-bottom-phone {
		padding-bottom: 80px;
	}
}


