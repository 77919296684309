//theme settings


//theme colors
.component-theme-default {
	background-color: transparent;
}

.component-theme-black {
	background-color: color('global', 'black');
}

.component-theme-gray {
	background-color: color('global', 'blue-gray');
}

.component-theme-white {
	background-color: $white;
}


//background gradients 
.background-gradient {
	background-color: rgba(0, 0, 0, .3);
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.background-adjust {
	@include mediaquery-max(tablet-portrait) {
		background-size: 250% auto !important;
	}
}

//hero heights
.xl-hero {
	height: 100vh;

	@include mediaquery(desktop) {
		height: 1200px;
	}
}
.tall-hero {
	height: 500px;
	transition: all 0.1s;
	z-index: 2;

	@include mediaquery(tablet-portrait) {
		height: 720px;
	}

}

.medium-hero {
	height: 250px;

	@include mediaquery(tablet-portrait) {
		height: 400px;

	}

	.video-wrapper {
		padding-bottom: 31.25%;
		max-height: 450px;
		min-height: 450px;

		iframe, video {
			max-height: 450px;
			min-height: 450px;
		}
	}
}

.small-hero {
	height: 350px;

	@include mediaquery(tablet-portrait) {
		height: 375px;

	}

}

//overlap for hero logo
.hero-logo-overlap {
	overflow: visible;
	z-index: 10;

	.component-hero-logo {
		bottom: 0;
		pointer-events: none;
		position: absolute;
		right: $gap*8;
		top: 65px;

		@include mediaquery(desktop) {
			right: $gap*12.5;
		}

		.silks-hero-logo {
			width: 154px;
			height: 831px;
			display: none;

			@include mediaquery(tablet-landscape) {
				display: block;

			}
		}
	}

}

//misc
.component-contact-cta {
	border-top: 1px solid color('global', 'gray');
}