//fonts
/**
 * @license
 * MyFonts Webfont Build ID 3623578, 2018-08-17T10:28:36-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-65-medium/
 * 
 * Webfont: AvenirLTPro-Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-95-black/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3623578
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3630903
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/374a9a");
@import url("//hello.myfonts.net/count/376737");
  
@font-face {font-family: 'AvenirLTPro-Medium';src: url('../fonts/374A9A_0_0.eot');src: url('../fonts/374A9A_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/374A9A_0_0.woff2') format('woff2'),url('../fonts/374A9A_0_0.woff') format('woff'),url('../fonts/374A9A_0_0.ttf') format('truetype');} 
@font-face {font-family: 'AvenirLTPro-Black';src: url('../fonts/374A9A_1_0.eot');src: url('../fonts/374A9A_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/374A9A_1_0.woff2') format('woff2'),url('../fonts/374A9A_1_0.woff') format('woff'),url('../fonts/374A9A_1_0.ttf') format('truetype');}
@font-face {font-family: 'AvenirLTPro-Book';src: url('../fonts/376737_0_0.eot');src: url('../fonts/376737_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/376737_0_0.woff2') format('woff2'),url('../fonts/376737_0_0.woff') format('woff'),url('../fonts/376737_0_0.ttf') format('truetype');}


// Font Mixins
// ======

@mixin sans-serif-book {
	font-family: "AvenirLTPro-Book", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin sans-serif-medium {
	font-family: "AvenirLTPro-Medium", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin sans-serif-black {
	font-family: "AvenirLTPro-Black", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}
// Grid and Responsive
// ===================
$gap						: 4px;
$site-width					: 1400px;
$nav-height					: 115px;
$nav-height-scrolled		: 75px;
$nav-height-desktop			: 170px;

$tablet-portrait-breakpoint	: 768px;
$tablet-landscape-breakpoint: 1024px;
$desktop-breakpoint			: 1100px;
$large-desktop-breakpoint	: 1440px;
$nav-breakpoint				: 1150px;

// Colors
// ======
$brand-color-key: 'red' !default;
$brand-colors: (
	'debug': (
		'red': 			#f00
	),
	'global': (
		'black': 			#231f20,
		'blue':				#01477a,
		'navy': 			#231f20,
		'brick': 			#ac574d,
		'dark-gray': 		#777777,
		'gray': 			#e5e5e5,
		'light-gray': 		#ededed,
		'medium-gray': 		#616161,
		'blue-gray': 		#32353E,
	)
);

// Standard colors
// ===============
$white: #FFFFFF;
$black: #000000;
$accentColor: #ac574d;

@function color($key: 'debug', $variant: $brand-color-key) {
	$color: null;
	$color-spectrum: map-get($brand-colors, $key);

	@if $color-spectrum {
			$color: map-get($color-spectrum, $variant);
	}

	@return $color;
}

// Generate background and text color classes based on the color map.
//
// Classes are named as follows:
//
// 		.bg-key-variant
// 		.text-color-key-variant
//
// For example:
//
// 		.bg-green-base
// 		.text-color-high-textDull
//
@each $key, $map in $brand-colors {
	@each $variant, $color in $map {
		.bg-#{$key}-#{$variant} {
			background-color: #{$color};
		}

		.text-color-#{$key}-#{$variant} {
			color: #{$color};
		}
	}
}

// Responsive Font Size Map
// ========================
$font-size-headline1: (
	null  : 48px,
	768px : 48px,
	1024px: 72px,
	1025px: 72px
);

$font-size-headline2: (
	null  : 48px,
	768px : 48px,
	1024px: 60px,
	1025px: 60px
);

$font-size-headline2-alt: (
	null  : 28px,
	768px : 28px,
	1024px: 60px,
	1025px: 60px
);

$font-size-headline3: (
	null  : 22px,
	768px : 22px,
	1024px: 48px,
	1025px: 48px
);

$font-size-headline4: (
	null	: 26px,
	768px : 26px,
	1024px: 36px,
	1025px: 36px
);

$font-size-headline5: (
	null  : 22px,
	768px : 22px,
	1024px: 28px,
	1025px: 28px
);

$font-size-headline6: (
	null  : 26px,
	768px : 26px,
	1024px: 26px,
	1025px: 26px
);

$font-size-author: (
	null  : 18px,
	768px : 26px,
	1024px: 26px,
	1025px: 26px
);

$font-size-p: (
	null  : 16px,
	768px : 16px,
	1024px: 21px,
	1025px: 21px
);

$font-size-blockquote: (
	null  : 28px,
	768px : 28px,
	1024px: 36px,
	1025px: 36px
);

$font-size-p-regular: (
	null  : 16px,
	768px : 16px,
	1024px: 18px,
	1025px: 18px
);

$font-size-p-small: (
	null  : 16px,
	768px : 16px,
	1024px: 16px,
	1025px: 16px
);
