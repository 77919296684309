address {
	font-size: 14px;
	line-height: 1.7;
	@include sans-serif-medium;
}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	letter-spacing: inherit;

	@include mediaquery(desktop) {
		&:hover {
			// text-decoration: underline;
		}
	}
}

mark {
	background-color: transparent;
}

em, i {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-style: italic;
}

hr {
	background: color('global', 'black');
	border: 0;
	height: 3px;
	margin: 25px auto;
	width: 80px;
}

p {
	font-size: 14px;
	color: inherit;
	line-height: 1.43;
	margin-bottom: 12px;
	@include sans-serif-medium;
	@include transition(color .3s ease-out);

	@include mediaquery(tablet-landscape) {
		font-size: 16px;
		line-height: 1.75;
	}

	a {
		text-decoration: none;

		@include mediaquery(desktop) {
			&:hover {
				color: white;
				opacity: .5;
				text-decoration: none;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.paragraph-large {
		font-size: 16px;
		line-height: 1.75;

		@include mediaquery(tablet-landscape) {
			font-size: 20px;
			line-height: 1.8;
		}
	}

	span {
		color: inherit;
		font: inherit;
	}
}

small {
	color: inherit;
	display: block;
	font-size: 16px;
	line-height: 1.75;
	@include sans-serif-medium;
}

span {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

strong, b {
	color: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	font-family: inherit;
	@include sans-serif-black;
}

th, tr, td {
	color: inherit;
}


.body-text {


	img, .post-img {
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
		width: auto;

		&.alignleft, &.post-img-left {
			display: block;
			margin: 0 auto $gap;
			max-width: 100%;
			width: auto;

			@include mediaquery(tablet-portrait) {
				clear: left;
				float: left;
				margin: 0 $gap*.5 $gap*.5 0;
			}
		}

		&.alignright, &.post-img-right {
			display: block;
			margin: 0 auto $gap;
			max-width: 100%;
			width: auto;

			@include mediaquery(tablet-portrait) {
				clear: right;
				float: right;
				margin: 0 0 $gap*.5 $gap*.5;

				&.single {
					margin: 0 -10% $gap*.5 $gap*.5;
				}
			}
		}
	}


	& + a {
		margin-top: $gap*1.5;
	}

	+ .ctas {
		// Add margin to ctas if it's next to a .body-text div
		margin-top: $gap*1.5;
	}

	.row-text-white & {
		color: $white;
	}

	.row-text-black & {
		color: color('global', 'black');
	}

	.button a {
		color: inherit;
		text-decoration: none;
	}

	ul, ol {

		li {
			font-size: 16px;
			line-height: 1.5;
			margin-bottom: 18px;
			@include sans-serif-medium;

			@include mediaquery(tablet-landscape) {
				font-size: 16px;
				line-height: 1.5;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ul {
		li {
			list-style-type: none;
			position: relative;
			padding-left: 40px;

			&:before {
				content: '';
				position: absolute;
				top: 6px;
				left: 0;
				width: 7px;
				height: 7px;
				border: 2px solid color('global', 'black');
				border-radius: 50%;
			}
		}
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	h1 {
		@extend .headline1;
	}

	h2 {
		@extend .headline2;
	}

	h3 {
		@extend .headline3;
		padding-bottom: $gap*3;

		@include mediaquery(tablet-portrait) {
			padding-bottom: $gap*10;
		}

		.page-template-default & {
			padding-bottom: $gap*2;
		}
	}

}

//button styles
.button {
	background-color: transparent;
	box-sizing: border-box;
	clear:both;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	height: 80px;
	letter-spacing: .5px;
	line-height: 1.5;
	margin: 0 auto;
	padding: 26px 10px 0;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	width: auto;
	overflow: hidden;
	@include sans-serif-black;
	@include transition(color .3s ease-out, border .3s ease-out);

	@include mediaquery(tablet-portrait) {
		min-width: 250px;
	}

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: color('global', 'black');
		position: absolute;
		z-index: -1;
		@include transition(left .3s ease-out);
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		left: -100%;
		top: 0;
		background-color: $accentColor;
		position: absolute;
		z-index: -1;
		@include transition(left .3s ease-out);
	}

	@include mediaquery(desktop) {
		&:hover {
			&:after {
				left: 100%;
			}

			&:before {
				left: 0;
			}

		}
	}

	
	&::-moz-selection { /* Code for Firefox */
		background: transparent;
	}

	&::selection {
		background: transparent;
	}

	&.button-small {
		font-size: 10px;
		line-height: 1.6;
		height: 36px;
		padding: 10px 15px;
		min-width: 0;
	}

	&.button-arrow {
		border: 3px solid color('global', 'black');
		color: color('global', 'black');
		padding-right: 95px;
		padding-left: 70px;
		padding-top: 26px;
		text-align: center;
		min-width: 250px;
		@include transition(color .3s ease-out, border .3s ease-out);

		@include mediaquery(tablet-portrait) {
			min-width: 310px;
		}

		&:after {
			background-color: $white;
		}

		mark {
			background: url('../images/long-arrow-black.png') center center no-repeat;
			background-size: 26px 13px;
			width: 26px;
			height: 13px;
			display: block;
			position: absolute;
			right: 46px;
			top: 29px;
			@include transition(right .3s ease-out);

			@include mediaquery(tablet-portrait) {
				right: 75px;
			}
		}

		@include mediaquery(desktop) {
			&:hover {
				color: white;
				border-color: $accentColor;

				mark {
					right: 69px;
					background: url('../images/long-arrow.png') center center no-repeat;
					background-size: 26px 13px;

				}	
			}
		}
	}

	&.button-white {
		border: 3px solid white;
		color: white;
		padding-left: $gap*12.5;
		padding-right: $gap*12.5;

		&:after {
			background-color: transparent;
		}

		@include mediaquery(desktop) {
			&:hover {
				color: white;
				border-color: $accentColor;
			}
		}
	}
}

.ctas {
	@include clearRow;
	margin-top: $gap*5;

	.button {
		cursor: pointer;
		display: block;
		margin: 0 auto $gap;
		vertical-align: middle;
		white-space: nowrap;

		.align-center & {
			margin: 0 0 $gap;
		}

		@include mediaquery(tablet-portrait) {
			display: inline-block;
			margin: 0 $gap*.5 $gap;
			max-width: none;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.error {
	color:red;
	font-size:13px;
	font-style:italic;
	text-transform:none;
	@include sans-serif-medium;
}

.hover-underline {
	&.sans-serif12,
	&.sans-serif14,
	&.sans-serif20,
	&.sans-serif36,
	&.sans-serif45,
	&.sans-serif78 {
		margin-bottom: $gap;

		a {
			@include mediaquery(desktop) {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.pagination {
	font-size: 13px;
	font-weight: 500;
	margin-right: $gap*.75;
	padding: 4px;
	text-align: right;

	.page-number {
		color: color('global', 'black');
		margin:0 $gap*.25;
		padding: 3px;

		&.activePage, &:hover {
			background-color: color('global', 'black');
			color: $white;
		}
	}
}


// Colors based on row color
.headline1,
.headline2,
.headline3,
.headline4,
h1, h2, h3, p, li {
	.row-text-white & {
		color: $white;

		&.black-text {
			color: color('global', 'black');
		}

		&.brick-text {
			color: $accentColor;
		}

		&.white-text {
			color: $white;
		}
	}

	.row-text-black & {
		color: color('global', 'black');

		&.black-text {
			color: color('global', 'black');
		}

		&.brick-text {
			color: $accentColor;
		}

		&.white-text {
			color: $white;
		}
	}

	span {
		color: inherit;
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
	}
}

.brick-text {
	color: $accentColor;
}


// override & helper classes
.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.black-text {
	color: color('global', 'black');
}

.brick-text {
	color: $accentColor;
}

.dark-gray-text {
	color: color('global', 'dark-gray');
}

.white-text {
	color: $white;
}

.no-margin {
	margin: 0;
}

.row-text-white {
	color: $white;
}

.row-text-black {
	color: color('global', 'black');
}

.underline {
	// for use in WYSIWYG as <u></u> was deprecated in 4.7+
	color:inherit;
	display: inline;
	font-family: inherit;
	font-size:inherit;
	font-style:inherit;
	font-weight:inherit;
	line-height: inherit;
	text-decoration:underline;

	@include mediaquery(desktop) {
		&:hover {
			a {
				color: $accentColor;
			}
		}
	}
}

.white-text {
	opacity: 1;
	@include transition(opacity .3s ease-out);

	.underline {
		@include mediaquery(desktop) {
			&:hover {
				a {
					color: white;
					opacity: .5;
				}
			}
		}
	}
}

.lowercase {
	text-transform: lowercase;
}

.nocase {
	text-transform: none;
}

.uppercase {
	text-transform: uppercase;
}

.weight-bold {
	@include sans-serif-black;
}

// .weight-light {
// 	@include sans-serif-light;
// }

.weight-medium {
	@include sans-serif-medium;
}

.bottom-border {
	border-bottom: 4px solid color('global', 'black');
	padding-bottom: 13px;
	margin-bottom: $gap*5;

	@include mediaquery(tablet-portrait) {
		padding-bottom: 30px;
		margin-bottom: $gap*10;		
	}

	.row-text-white & {
		border-color: $white;
	}

	&.bottom-border-small {
		padding-bottom: 13px;
		margin-bottom: $gap*5;
	}
}

.hover-underline {
	a {
		background-image: linear-gradient(180deg, transparent 94%, #000 0);
		background-repeat: no-repeat;
		background-size: 0 100%;
		text-decoration: none;
		-webkit-transition: background-size 0.4s ease;
		-moz-transition: background-size 0.4s ease;
		transition: background-size 0.4s ease;

		&:after {
			content: " ";
			display: block;
			margin-bottom: inherit;
		}

		&:hover {
			background-size: 100% 100%;
			text-decoration: none;
		}
	}

	&.hover-underline-white {
		a {
			background-image: linear-gradient(180deg, transparent 94%, #FFFFFF 0);
		}
	}
}

// Text Headline

.headline1 {
	font-size: 36px;
	line-height: 1.44;
	@include sans-serif-black;

	@include mediaquery(tablet-landscape) {
		font-size: 60px;
		line-height: 1.3;
	}
}

.headline1-narrow {
	@extend .headline1;
	@include sans-serif-book;
}

.headline2 {
	font-size: 28px;
	line-height: 1.43;
	@include sans-serif-black;

	@include mediaquery(tablet-landscape) {
		font-size: 36px;
		line-height: 1.44;
	}
}

.headline3 {
	font-size: 20px;
	line-height: 1.6;
	@include sans-serif-black;

	@include mediaquery(tablet-landscape) {
		font-size: 24px;
		line-height: 1.67;
	}
}

.headline-outline {
	text-transform: uppercase;
	color: black;
	-webkit-text-fill-color: white; /* Will override color (regardless of order) */
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: black;
}


//Text styles
.graph-text {
	font-size: 12px;
	line-height: 1.33;
	margin-bottom: 8px;
	@include sans-serif-medium;

	@include mediaquery(tablet-landscape) {
		font-size: 14px;
		line-height: 1.7;
	}
}

legend {
	padding: $gap*8 $gap*5;

	span {
		display: block;
		margin-bottom: $gap*3;
		font-size: 14px;
		@include sans-serif-black;

		&:last-child {
			margin-bottom: 0;
		}
	}

	svg, mark {
		vertical-align: -7px;
		margin-right: $gap*1.5;
	}
}