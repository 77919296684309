//gallery component
.gallery-popup {
	bottom: 10px;
	position: absolute;
	right: 0;
}

.gallery-expander {
	.openGallery {
		display: none;
	}
}

.component-gallery {
	position:relative;
	text-align: right;

	.gallery-container {
		// background-color: tomato;
	}

	.gallery-group {
		width: 100%;

		@include mediaquery(tablet-portrait) {
			@include flexbox(flex);
			@include flexbox-align-items(stretch);
			@include flexbox-justify-content(space-between);
			margin-bottom: $gap*4;
		}

		@include mediaquery(tablet-portrait) {
			margin-bottom: 34px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.gallery-item {
		cursor: pointer;
		width: calc(50% - 8px);
		text-align: left;
		height: 0;
		padding-bottom: calc(50% - 8px);
		position: relative;
		overflow: hidden;
		margin-bottom: $gap*4;
		@include transition(opacity .4s ease-out);

		@include mediaquery(tablet-portrait) {
			width: 100%;
			padding-bottom: 100%;
			margin-bottom: 0;
		}

		@include mediaquery(desktop) {
			&:hover {
				opacity: .8;
			}
		}

		img {
			position: absolute;
			height: auto;
			min-height: 100%;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	.gallery-group-5 {

		@include mediaquery(tablet-portrait) {
			@include flexbox-wrap(no-wrap);
		}

		.gallery-item {
			@include mediaquery(tablet-portrait) {
				width: 50%;
				padding-bottom: 50%;
			}
		}
	}

	.gallery-group-4 {
		@include flexbox(flex);
		@include flexbox-align-items(stretch);
		@include flexbox-justify-content(space-between);
		@include flexbox-wrap(wrap);

		@include mediaquery(tablet-portrait) {
			@include flexbox-wrap(no-wrap);
		}

		.gallery-item {

			@include mediaquery(tablet-portrait) {
				width: calc(25% - 12px);
				padding-bottom: calc(25% - 12px);

			}

			@include mediaquery(desktop) {
				width: calc(25% - 26px);
				padding-bottom: calc(25% - 26px);

			}
		}
	}

	.gallery-group-inner-large {

		@include mediaquery(tablet-portrait) {
			width: calc(50% - 8px);
		}

		@include mediaquery(desktop) {
			width: calc(50% - 17px);
		}

		.gallery-item {
			width: 100%;
			padding-bottom: 100%;

		}
	}

	.gallery-group-inner-4 {
		@include flexbox(flex);
		@include flexbox-align-items(stretch);
		@include flexbox-justify-content(space-between);
		@include flexbox-wrap(wrap);

		@include mediaquery(tablet-portrait) {
			width: calc(50% - 8px);
		}

		@include mediaquery(desktop) {
			width: calc(50% - 17px);
		}

		.gallery-item {

			@include mediaquery(tablet-portrait) {
				width: calc(50% - 8px);
				padding-bottom: calc(50% - 8px);
				margin-bottom: $gap*4;

				&:nth-last-child(2), &:last-child {
					margin-bottom: 0;
				}
			}


			@include mediaquery(tablet-portrait) {
				width: calc(50% - 17px);
				padding-bottom: calc(50% - 17px);
				margin-bottom: 34px;

				&:nth-last-child(2), &:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.close {
		right: $gap*10;
		top: $gap*10;
	}

	.gallery-popup {
		background-color: rgba(255, 255, 255, .92);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		display: none;
	}

	.gallery-popup-inner {
		background-color: color('global', 'grey');
		margin: 0 auto;
		max-width: 1240px;
		position: relative;
		width: 80%;
		z-index: 10;
	}

	.gallery-popup-items {
		height: 70vh;
		overflow: hidden;
		position: relative;
		width: 100%;
		z-index: 1;
	}

	.gallery-popup-item {
		bottom: 0;
		height: 100%;
		left: 0;
		margin: 0 auto;
		opacity: 0;
		position: absolute;
		right: 0%;
		top: 0%;
		width: auto;
		z-index: 0;
		@include transition(opacity .5s ease-in, z-index 1.5s ease-in);

		img {
			display: block;
			height: 100%;
			transform: scale(1.3);
			width: auto;
			margin: 0 auto;
			@include transition(all 1s ease-out);
		}

		&.activeSlide {
			opacity: 1;
			z-index: 1;

			img {
				transform: scale(1);
			}
		}
	}

	.arrow {
		cursor: pointer;
		position: absolute;
		top: 50%;
		right: $gap*2;
		width: 50px;
		height: 20px;
		z-index: 100;

		@include mediaquery(tablet-portrait) {
			right: $gap*5;
		}

		@include mediaquery(tablet-landscape) {
			right: $gap*10;
		}

		.long-arrow {
			width: 50px;
			height: 20px;
			stroke: color('global', 'black');
		}

		&.arrow-prev {
			right: auto;
			left: $gap*2;

			@include mediaquery(tablet-portrait) {
				left: $gap*5;
			}

			@include mediaquery(tablet-landscape) {
				left: $gap*10;
			}

			.long-arrow {
				@include transform(rotate(-180deg));
			}
		}

	}


	&.galleryOpened {
		.gallery-popup {
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-justify-content(center);
			z-index: 1001;
		}
	}
}
