.component-availabilities-floors {
	@include mediaquery(tablet-landscape) {
		border-top: 1px solid color('global', 'black');
		border-bottom: 1px solid color('global', 'black');
		margin-bottom: $gap*15;
		@include flexbox(flex);
		@include flexbox-align-items(stretch);
		@include flexbox-justify-content(space-between);
	}

	.floor-list {
		@include mediaquery-max(tablet-landscape) {
			border: 2px solid color('global', 'black');
			height: 52px;
			margin-bottom: $gap*5;
			margin:0 auto $gap*5;
			max-height: 52px;
			max-width: 280px;
			overflow: hidden;
			padding: 7px $gap*5 0 $gap*5;
			position: relative;
			width: calc(100% - 40px);
			@include transition(max-height .4s ease-out);

			&.expandList {
				height: 100%;
				max-height: 1000px;
			}
		}

		@include mediaquery(tablet-landscape) {
			width: 29.5%;
			text-align: left;
			border-right: 1px solid color('global', 'black');
		}
	}

	.floor-toggle {
		cursor: pointer;
		width: 90%;
		margin-bottom: $gap*3;

	    @include mediaquery-max(tablet-landscape) {
	    	&.activeFloor {
	    	 	-webkit-text-fill-color: black;
	    	}

	    	&[data-floor-id="AllFloors"] {
	    		-webkit-text-fill-color: white;	
	    	}
	    }

		@include mediaquery(tablet-landscape) {
			height: 76px;
			border-bottom: 1px solid color('global', 'gray');
			padding: $gap*4 0 0 0;
			width: 100%;
			margin: 0;

			&:last-child {
				border-bottom: 0;
			}

			span {
				display: block;
				width: 241px;
				margin-left: calc(100% - 241px);
				padding-right: $gap*5;
			}

			&.activeFloor, &:hover {
				background-color: color('global', 'black');
				color: $white;
				border-color: color('global', 'black');
			    -webkit-text-stroke-width: 0;

			}
		}

		&:nth-last-child(2) {
			margin-bottom: 0;
			border-bottom: 0;
		}

		&.displayFloor {
			@include mediaquery(tablet-landscape) {
				display: none;
			}
		}

	}

	.landscape {
		display: none;

		@include mediaquery(tablet-landscape) {
			display: block;
			width: 241px;
			margin-left: calc(100% - 241px);
			padding: $gap*5 $gap*5 $gap*5 0;
		}
	}

	.expand-list {
		@include mediaquery(tablet-landscape) {
			display: none;
		}
	}

	.floor-plans-wrapper {
		@include mediaquery-max(tablet-landscape) {
			padding: $gap*5;
			border-top: 4px solid color('global', 'black');
		}

		@include mediaquery(tablet-landscape) {
			width: 44%;
			padding: $gap*4 $gap*5;
			position: relative;
			height: 380px;
			border-right: 1px solid color('global', 'black');
		}

		@include mediaquery(desktop) {
			// width: 74%;
		}

	}

	.floor-plans {
		position: relative;
		height: 100%;
		overflow: hidden;
		margin-bottom: $gap*3;

		@include mediaquery(tablet-landscape) {
			width: 100%;
			margin: 0;
		}
	}

	.floor-plan {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0;
		z-index: -1;
		height: 100%;
		@include transition(opacity .4s ease-out, z-index .4s ease-out);

		@include mediaquery-max(tablet-landscape) {
			position: relative;
			max-height: 0;
			overflow: hidden;
			@include transition(opacity .4s ease-out, z-index .4s ease-out, max-height .4s ease-out);
		}

		&.activeFloor {
			opacity: 1;
			z-index: 10;
			@include mediaquery-max(tablet-landscape) {
				max-height: 1000px;
			}
		}

		img {
			width: 100%;
			height: auto;
			margin: 0 auto;
			display: block;

			@include mediaquery(tablet-landscape) {
				width: auto;
				max-width: 100%;
				max-height: 100%;
				height: auto;
			}
		}
	}

	.legend-wrapper {
		@include mediaquery(tablet-landscape) {
			width: 26.5%;
			padding-left: $gap*10;
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-justify-content(flex0-start);
		}
	}

}

.component-availabilities {
	.floor-plan-popup {
		background-color: rgba(255, 255, 255, .92);
		display: none;
		height: 100vh;
		left: 0;
		padding: $gap*5 $gap*5 $gap*3;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 100;

		&.showDetail {
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-justify-content(center);
		}
	}

	.floor-plan-popup-inner {
		height: 90vh;
		max-width: 95%;
		@include flexbox(flex);
		@include flexbox-align-items(center);
		@include flexbox-justify-content(center);

		@include mediaquery(tablet-landscape) {
			max-width: 80%;
		}

		img {
			width: auto;
			height: auto;
			max-height: 100%;
			max-width: 100%;
		}
	}

}

.component-availabilities-listings {
	.floor {
		display: none;
		background-color: color('global', 'blue-gray');
		padding-top: $gap*2;

		&.activeFloor {
			display: block;
		}
	}

	.listings-wrapper {

	}


	.listings-table {
		h5 {
			font-size: 14px;
			display: block;
			line-height: 14px;
			width: 42.5%;
			@include sans-serif-black;

			@include mediaquery(tablet-landscape) {
				display: none;
			}
		}

		p {
			font-size: 12px;
			width: 57.5%;
			padding-left: $gap*3;
			@include sans-serif-medium;

			@include mediaquery(tablet-landscape) {
				font-size: 16px;
				width: 100%;
				padding: 0 $gap*5 0 0;
			}
		}

		.suite p {
			font-size: 16px;
			line-height: 14px;
			@include sans-serif-black;

			@include mediaquery(tablet-landscape) {
				font-size: 20px;
			}

		}

		.no-matches {
			.listing {
				width: 100%;
			}

			p {
				width: 100%;
				padding-left: 0;
				font-size: 14px;
				text-align: center;
				@include sans-serif-black;

				@include mediaquery(tablet-landscape) {
					font-size: 16px;
				}
			}
		}

		.listings-header {
			display: none;

			@include mediaquery(tablet-landscape) {
				padding-top: $gap*8;
				width: 1024px;
				margin: 0 auto;

				@include flexbox(flex);
				@include flexbox-justify-content(space-between);
			}
		}

		.listings-label, .listing {

			@include mediaquery(tablet-landscape) {
				width: 10%;

				&.features {
					width: 25%;
				}

				&.date {
					width: 14%;
				}

				&.type  {
					width: 16%;
				}

				&.price {
					margin-left: 30px;
				}
			}
		}

		.listings-label h2 {
			font-size: 16px;
			@include sans-serif-black;
		}

		.listing-row {
			padding: $gap*5 $gap*5 $gap*2;
			border-bottom: 1px solid color('global', 'gray');
			display: none;

			&:last-child, &:nth-last-child(2) {
				border-bottom: 0;
			}

			&.activeFloor {
				display: block;
			}
		}

		.listing-row-inner {
			@include mediaquery(tablet-landscape){
				width: 1024px;
				margin: 0 auto;
				@include flexbox(flex);
				@include flexbox-justify-content(space-between);

			}
		}

		.listing {
			margin-bottom: $gap*3;
			@include flexbox(flex);
			@include flexbox-justify-content(flex-start);

			@include mediaquery(tablet-landscape) {
				display: block;
			}

			.button {
				margin: 0 0 0 $gap*3;

				@include mediaquery(tablet-landscape) {
					margin: 0;
				}
			}

		}

		.listings-body {
			@include mediaquery(tablet-landscape) {
				border-top: 1px solid color('global', 'gray');
				margin-top: $gap*8;
			}
		}

	}
}