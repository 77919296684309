.social {
	background-color: transparent;
	display: inline-block;
	border: 2px solid color('global', 'black');
	border-radius: 50%;
	width: 80px;
	height: 80px;
	color: color('global', 'black');
	text-align: center;
	@include transition(background-color .3s ease-out, border .3s ease-out);

	i {
		color: color('global', 'black');
		font-size: 37px;
		padding: 19px;

		&.fa-twitter {
			padding-left: 21px;
		}
	}

	&.social-small {
		border: 1px solid color('global', 'white');
		width: 40px;
		height: 40px;
		color: $white;

		i {
			color: $white;
			font-size: 23px;
			margin-top: 7px;
			padding: 0;

			&.fa-twitter {
				font-size: 22px;
				margin-top: 9px;
				margin-left: 2px;
			}

			&.fa-facebook-f {
				font-size: 22px;
				margin-top: 9px;
			}
		}

	}

	@include mediaquery(desktop) {
		&:hover {
			color: $white;
			background-color: $accentColor;
			border-color: $accentColor;

			i {
				color: $white;
			}
		}
	}
}

.hamburger {
	background-color: transparent;
	cursor: pointer;
	display: block;
	height: 40px;
	border-radius: 0;
	position: fixed;
	right: 20px;
	top: 29px;
	width: 40px;
	z-index: 100;
	@include transition(background-color .1s ease-in, border-radius .1s ease-in, top .4s ease-out);

	@include mediaquery(tablet-landscape) {
		display: none;
	}

	&:after, &:before {
		background-color: $white;
		content: '';
		height: 3px;
		left: 0;
		position: absolute;
		top: 13px;
		width: 40px;
		@include transition(all .1s ease-in);

		.home & {
			background-color: $white;
		}
	}

	&:after {
	  top: 23px;
	}

	.navOpen & {
		border-radius: 50%;
		background-color: color('global', 'black');

		&:after {
			background-color: $white;
			width: 12px;
			top: 19px;
			left: 14px;
			@include transform-rotate(45deg);
		}

		&:before {
			width: 12px;
			top: 19px;
			left: 14px;
			background-color: $white;
			@include transform-rotate(-45deg);
		}

	}

	.hideNav & {
		top: -100px;
	}

}

.expand-list {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 60px;
	height: 48px;
	z-index: 10;
	padding: $gap*5 0 0 $gap*7.5;

	.arrow {
		width: 14px;
		height: 8px;
		@include transition(all .4s ease-out);
		@include transform-origin(center center);
		
		.expandList & {
			@include transform(rotate(-180deg));
		}
	}

}


.close {
	background-color: $white;
	border-radius: 50%;
	cursor: pointer;
	display: block;
	height: 48px;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 48px;
	z-index: 100;

	&:after, &:before {
		content: '';
		position: absolute;
		background-color: color('global', 'black');
		height: 3px;	
		width: 15px;		
	}

	&:after {
		top: 22px;
		left: 17px;
		@include transform-rotate(45deg);
	}

	&:before {
		top: 22px;
		left: 17px;
		@include transform-rotate(-45deg);
	}

	&.close-black {
		background-color:  color('global', 'black');

		&:after, &:before {
			background-color: $white;
		}
	}
}

.mens-restroom {
	width: 24px;
	height: 24px;
}

.womens-restroom {
	width: 24px;
	height: 24px;
}

.available {
	width: 24px;
	height: 24px;
	display: inline-block;
	background:url('../images/available.png') no-repeat center center;
	background-size: cover;
}

//unused
.circle-button {
	color: white;
	cursor: pointer;
	display: block;
	font-size: 12px;
	letter-spacing: 4px;
	padding-top: $gap*1.5;
	position: relative;
	text-align: center;
	text-transform: uppercase;

	@include mediaquery(tablet-portrait) {
		font-size: 14px;
		padding-top: $gap*2;
	}

	&:before {
		background-color: rgba(255, 255, 255, 0);
		border-radius: 50%;
		border: 2px solid white;
		content: '';
		height: 16px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		top: 0;
		width: 16px;
		@include transition(background-color .4s ease-out);
	
		@include mediaquery(tablet-portrait) {
			height: 21px;
			width: 21px;
		}
	}

	@include mediaquery(desktop) {
		&:hover {
			&:before {
				background-color: rgba(255, 255, 255, .5);
			}
		}

		&:focus {
			&:before {
				background-color: rgba(255, 255, 255, 1);
			}
		}
	}

	&.circle-button-black {
		color: color('global', 'navy');

		&:before, &:after {
			border: 2px solid color('global', 'navy');
			background-color: rgba(color('global', 'navy'), 0);

		}

		@include mediaquery(desktop) {
			&:hover {
				&:before {
					background-color: rgba(color('global', 'navy'), .5);
				}
			}

			&:focus {
				&:before {
					background-color: rgba(color('global', 'navy'), 1);
				}
			}
		}
	}
}

.map-cta {
	position: fixed;
	right: 10px;
	top: calc(100vh - 225px);
	z-index: 100;
	width: 130px;
	text-align: center;
	opacity: 1;
	@include transition(opacity .4s ease-out);

	@include mediaquery(tablet-portrait) {
		top: calc(100vh - 150px);
	}

	@include mediaquery(tablet-landscape) {
		width: 150px;
		right: 30px;
		top: calc(100vh - 160px);
	}

	&.nearFooter {
		opacity: 0;
	}

	&.safariBarGone {
		@include mediaquery-max(tablet-portrait) {
			top: calc(100vh - 125px);
		}		
	}
}

.map-icon-text {
	font-size: 14px;
	color: white;
	text-align: center;
	display: block;
	margin-top: 10px;
	text-shadow: 0 2px 4px rgba(0,0,0,0.50);
	@include sans-serif-medium;
}

.map-button {
	background-color: color('global', 'navy');
	border-radius: 50%;
	border: 2px solid white;
	cursor: pointer;
	width: 70px;
	height: 70px;
	display: block;
	text-align: center;
	padding-top: 16px;
	margin: 0 auto;
	@include transform(scale(1));
	@include transition(all .4s ease-out);

	@include mediaquery(tablet-landscape) {
		width: 120px;
		height: 120px;
		padding-top: 28px;
	}

	a {
		display: block;
	}

	.map-icon {
		stroke: white;
		width: 19px;
		height: 35px;
		@include transition(stroke .4s ease-out);

		@include mediaquery(tablet-landscape) {
			width: 34px;
			height: 58px;
		}

	}

	@include mediaquery(desktop) {
		&:hover {
			background-color: white;
			border-color: color('global', 'navy');
			@include transform(scale(1.2));

			.map-icon {
				stroke: color('global', 'navy');
			}
		}
	}
}

.zoom {
	background-color: color('global', 'navy');
	border: 2px solid #b6b8ba;
	border-radius: 50%;
	cursor: pointer;
	display: block;
	height: 35px;
	position: absolute;
	width: 35px;
	@include transition(background-color .4s ease-out, border .4s ease-out);
	//set position in component where it's being used

	@include mediaquery(tablet-landscape) {
		width: 45px;
		height: 45px;
	}

	&:before {
		content: '';
		position: absolute;
		height: 1px;
		width: 17px;
		background-color: white;
		top: 15px;
		left: 7px;
		@include transition(background-color .4s ease-out);

		@include mediaquery(tablet-landscape) {
			width: 22px;
			top: 20px;
			left: 10px;
		}
	}

	&.zoom-in {
		&:after {
			content: '';
			position: absolute;
			height: 17px;
			width: 1px;
			background-color: white;
			top: 7px;
			left: 15px;
			@include transition(background-color .4s ease-out);

			@include mediaquery(tablet-landscape) {
				height: 22px;
				top: 9px;
				left: 20px;
			}
		}
	}

	&.zoomMax {
		border: 2px solid color('global', 'navy');
		background-color: white;
		pointer-events: none;

		&:before, &:after {
			background-color: color('global', 'navy');
		}
	}

	@include mediaquery(desktop) {
		&:hover {
			border: 2px solid color('global', 'navy');
			background-color: white;

			&:before, &:after {
				background-color: color('global', 'navy');
			}
		}
	}
}

.gallery-nav-item {
	background-color: rgba(255, 255, 255, 0);
	border-radius: 50%;
	border: 1px solid white;
	display: inline-block;
	height: 8px;
	margin: 0 $gap*.75;
	width: 8px;
	@include transition(background-color .4s ease-out);
	@include shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.75));

	&.activeTab {
		background-color: rgba(255, 255, 255, 1);
	}

	@include mediaquery(tablet-landscape) {
		height: 16px;
		margin: 0 $gap*1.25;
		width: 16px;
	}


	@include mediaquery(desktop) {
		&:hover {
			background-color: rgba(255, 255, 255, .5);
		}

		&:focus {
			background-color: rgba(255, 255, 255, 1);
		}
	}
}


.full-screen {
	background-color: color('global', 'navy');
	border-radius: 50%;
	border: 2px solid white;
	cursor: pointer;
	display: block;
	height: 40px;
	position: absolute;
	text-align: center;
	width: 40px;
	z-index: 100;
	//set position in component where it's being used
	@include transition(background-color .4s ease-out, border .4s ease-out);

	@include mediaquery(tablet-landscape) {
		height: 66px;
		width: 66px;
	}

	.fa-expand {
		color: white;
		font-size: 20px;
		position: absolute;
		top: 9px;
		left: 0;
		right: 0;
		@include transition(color .4s ease-out);

		@include mediaquery(tablet-landscape) {
			font-size: 25px;
			top: 18px;
		}
	}

	@include mediaquery(desktop) {
		&:hover {
			border: 2px solid color('global', 'navy');
			background-color: white;

			.fa-expand {
				color: color('global', 'navy');
			}
		}
	}
}



.play {
	cursor: pointer;
	margin: 0 auto;
	position: absolute;
	z-index: 10;
	@include flexbox(flex);
	@include flexbox-align-items(center);
	@include flexbox-justify-content(center);

	//set position in component where it's being used

	.play-button {
		background-color: rgba(0, 0, 0, .4);
		border-radius:50%;
		border: 2px solid white;
		height: 80px;
		width: 80px;
		@include flexbox(flex);
		@include flexbox-align-items(center);
		@include flexbox-justify-content(center);
		@include transition(background-color .4s ease-out);
		
		@include mediaquery(desktop){
			width: 160px;
			height: 160px;
		}
	}

	.play-arrow {
		border-left: 1px solid white;
		left: 4px;
		width: 19px;
		height: 19px;
		position: relative;
		@include transition(border .4s ease-out);
		
		@include mediaquery(desktop){
			width: 37px;
			height: 37px;
			left: 5px;
		}

		&:after, &:before {
			background-color: white;
			content: '';
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: 21px;
			z-index: 10;
			@include transform-rotate(-26deg);
			@include transition(background-color .4s ease-out);
			transform-origin: left;

			@include mediaquery(desktop) {
				width: 41px;
			}
		}

		&:after {
			bottom: 0;
		}

		&:before {
			top: 0;
			@include transform-rotate(26deg);
		}
	}

	@include mediaquery(desktop) {
		&:hover {
			.play-button {
				background-color: white;
			}

			.play-arrow {
				border-left: 1px solid black;
				
				&:after, &:before {
					background-color: black;
				}
			}

		}
	}
}

.scroller {
	bottom: -30px;
	cursor: pointer;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	width: 100%;
	z-index: 10;
}

.map-point {
	background-color: rgba(color('global', 'navy'), .8);
	border-radius: 50%;
	border: 1px solid white;
	color: white;
	cursor: pointer;
	font-size: 5px;
	height: 27px;
	letter-spacing: 0;
	line-height: 1.1;
	padding: 5px 3px 3px 3px;
	text-align: center;
	text-transform: uppercase;
	width: 27px;
	@include flexbox(flex);
	@include flexbox-align-items(center);
	@include flexbox-justify-content(center);
	@include transform(scale(1));
	@include transform-origin(center center);
	@include transition(all .4s ease-out);

	.row-text-white &, .row-text-black & {
		color: white;
	}
				
	@media only screen and (min-width: 415px) {
		width: 40px;
		height: 40px; 
		font-size: 8px;
	}

	@include mediaquery(tablet-portrait) {
		background-color: color('global', 'navy');
		border: 2px solid white;
		color: white;	
		height: 62px;
		width: 62px;
		font-size: 11px;
	}


	@include mediaquery(desktop) {
		width: 80px;
		height: 80px;
		font-size: 14px;

		&:hover {
			border-color: color('global', 'navy');
			background-color: white;
			color: color('global', 'navy');
			@include transform(scale(1.2));
		}
	}


	span {
		display: none;

		@include mediaquery(tablet-portrait) {
			display: block;
		}
	}

}

.slider-arrow {
	background-color: rgba(35, 31, 32, .8);
	width: 40px;
	cursor: pointer;
	height: 100px;
	display: block;
	position: absolute;
	top: calc(50% - 50px);
	z-index: 100;

	@include mediaquery(tablet-landscape) {
		width: 80px;
		height: 205px;
		top: calc(50% - 103px);
	}

	&:after {
		content: '';
		background-image: url('../images/short-arrow.png');
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		width: 15px;
		height: 13px;
		display: block;
		top: calc(50% - 7px);
		left: 13px;

		@include mediaquery(tablet-landscape) {
			width: 31px;
			height: 26px;
			top: calc(50% - 16px);
			left: 27px;
		}
	}

	&.slider-arrow-prev {
		left: 0;

		&:after {
			@include transform(rotate(-180deg));
		}
	}

	&.slider-arrow-next {
		right: 0;
	}
}


// Accessibility trick to hide span nearby with aria values
.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
	position: absolute;
    width: 1px;
}
