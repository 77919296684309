.main-header {
	background-color: color('global', 'blue-gray');
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 11px 0 0 0;
	z-index: 100;
	height: 96px;
	@include transition(top .2s ease-out);

	@include mediaquery(tablet-landscape) {
		height: auto;
	}

	@include mediaquery(desktop) {
		padding-top: $gap*8;
	}

	.home & {
		// background-color: transparent;
	}

	.hideNav & {
		top: -214px;
	}

	.popupOpen & {
		top: -214px;
	}

	.popupOpen.popupTop & {
		top: 0;
	}


	.navOpen & {
		z-index: 1000;
	}

	.zindexBottom & {
		z-index: 0;
	}

	.logo-wrapper {
		max-width: 170px;
		height: 75px;
		margin: 0 0 $gap*8 $gap*5;

		@include mediaquery(tablet-landscape) {
			max-width: 340px;
			height: 150px;
		}

		@include mediaquery(desktop) {
			left: 0px;
			margin: 0 auto $gap*8;
		}

		a {
			
			max-width: 170px;
			height: 75px;
			@include flexbox(flex);
			@include flexbox-align-items(center);

			@include mediaquery(tablet-landscape) {
				max-width: 340px;
				height: 150px;
			}
		}

		.site-logo {
			max-width: 170px;
		    max-height: 75px;
		    width: auto;
		    height: auto;
		    margin: 0 auto;
		    display: block;

		    @include mediaquery(tablet-landscape) {
				max-width: 340px;
				max-height: 150px;
			}
		}

	}

	.header-inner {
		padding: 0;
		width: 100%;
		position: relative;

		@include mediaquery(tablet-landscape) {
			border-top: 3px solid white;
			border-bottom: 3px solid white;
			padding: $gap*5 0 0 0;
		}
	}

	.main-nav {
		@include mediaquery-max(tablet-landscape) {
			position: fixed;
			top: -20px;
			right: -100vw;
			background-color: $white;
			height: 120vh;
			width: 100vw;
			z-index: 10;
			padding-top: 129px;
			overflow: hidden;
			@include transition(right .4s ease-out);

			.navOpen & {
				right: 0;
			}
		}

		@include mediaquery(tablet-landscape) {
			max-width: $tablet-landscape-breakpoint;
			margin: 0 auto;
			width: 100%;
			text-align: center;
		}

		.menu-items {
			@include mediaquery-max(tablet-landscape) {
				width: 120vw;
				overflow: scroll;
				height: 90%;
				padding-bottom: 60px;
				text-align: center;
			}

			@include mediaquery(tablet-landscape) {
				text-align: center;
			}
		}

		.menu-item {
			&:not(.button) {
				@include mediaquery-max(tablet-landscape) {
					text-align: center;
					height: 92px;
					border-top: 1px solid color('global', 'gray');
					margin-left: -20vw;
					@include sans-serif-black;

					a {
						font-size: 36px;
						line-height: 1.44;
						color: black;
						-webkit-text-fill-color: white; /* Will override color (regardless of order) */
						-webkit-text-stroke-width: 1px;
						-webkit-text-stroke-color: black;
						display: block;
						width: 100%;
						height: 100%;
						padding-top: 25px;
					}

				}
			}

			@include mediaquery(tablet-landscape) {
				bottom: -3px;
				color: $white;
				display: inline-block;
				font-size: 14px;
				margin: 0;
				overflow: hidden;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				@include sans-serif-black;
				

				a {
					display: block;
					width: 100%;
					height: 100%;
					overflow: hidden;
					position: relative;
					letter-spacing: .5px;
					padding-bottom: $gap*5;

					@include mediaquery(tablet-landscape) {
						padding:0 $gap*5 $gap*5;
					}

					&:after {
						background: $white;
						bottom: 0px;
						content: '';
						height: 10px;
						left: -100%;
						position: absolute;
						width: 100%;
						@include transition(left .3s ease-out);
					}

				}
				
				&:hover, &.current_page_item {
					a:after {
						left: 0;
					}
				}
			}

			&:nth-last-child(3) {
				@include mediaquery-max(tablet-landscape) {
					border-bottom: 1px solid color('global', 'gray');
				}
			}

			&:nth-last-child(2) {
				@include mediaquery-max(tablet-landscape) {
					display: none;
				}
			}
		}

		
		.button {
			display: inline-block;
			width: 280px;
			margin: 20px 0 0 -20vw;
			padding: 0;
		
			@include mediaquery(tablet-landscape) {
				display: none;
			}

			a {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				padding: 26px 95px 19px 70px;

				&:after {
					content: '';
					background: url('../images/long-arrow-black.png') center center no-repeat;
					background-size: 26px 13px;
					width: 26px;
					height: 13px;
					display: block;
					position: absolute;
					right: 46px;
					top: 29px;
				}
			}
		}
	}
}