.page-footer {
	background-color: color('global', 'black');
	color: white;
	text-align: center;
	padding: 0 $gap*5 $gap*5;

	@include mediaquery(desktop) {
		padding: 0 0 $gap*10;
	}

	.copyright {
		margin: $gap*5 0 0 0;
		display: inline-block;

		@include mediaquery(tablet-landscape) {
			text-align: right;
			margin: 0;
		}
	}

	.small-footer-info {
		margin-top: 20px;

		@include mediaquery(tablet-landscape) {
			text-align: right;
		}
	}

	.housing {
		display: inline-block;
		width: 30px;
		text-align: right;
		margin-right: 10px;
		vertical-align: -8px;
	}

	.column {
		@include mediaquery-max(tablet-landscape) {
			width: 100% !important;
			//keeps footer stacked vertically on tablet tablet-portrait
		}
	}

	.footer-inner {
		padding: $gap*5 0 0;
		text-align: center;

		@include mediaquery(tablet-landscape) {
			padding: $gap*10 0 0;
		}
	}

	.footer-wrapper {
		max-width: 1024px;
		margin: 0 auto;		
	}

	.footer-border {
		border-bottom: 4px solid white;

		padding: $gap*5 0;

		@include mediaquery(tablet-landscape) {
			padding: $gap*10 0;
		}
	}

	.mobile {
		@include mediaquery(tablet-portrait) {
			display: none;
		}
	}

	.tablet {
		display: none;

		@include mediaquery(tablet-portrait) {
			display: inline;
		}
	}

	.footer-logo {
		width: 61px;
		height: 61px;
		display: block;
		margin: 0 auto $gap*4;

		@include mediaquery(tablet-landscape) {
			margin: 0 0 $gap*4;
		}

		.tei-logo {
			width: 61px;
			height: 61px;
		}
	}

	.footer-legal {
		max-width: 180px;
		margin: 0 auto;
		@include clearRow;

		@include mediaquery(tablet-landscape) {
			text-align: left;
			max-width: none;
			margin: 0;
		}

		small {
			width: 50%;
			float: left;

			@include mediaquery(tablet-landscape) {
				float: none;
				width: auto;
			}

		}
	}

	.last-column {
		@include mediaquery(desktop) {
			text-align: right;
		}
	}

	.footer-office, .footer-contact, .footer-social {
		small {
			margin-bottom: $gap*2;
		}

		.social {
			margin: 0 6px;
		}
	}

	.underline a {
		padding-right: $gap*2;
	}

}
