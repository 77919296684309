.component-text{
	padding: 0 $gap;

	@include mediaquery(tablet-landscape) {
		padding: 0;
	}

	.body-text.align-center {
		max-width: 500px;
		margin: $gap*3 auto 0;

		@include mediaquery(tablet-landscape) {
			max-width: 850px;
			margin: $gap*3 auto 0;
		}
	}

	.text-icon {
		width: auto;
		max-width: 100%;
		margin-bottom: $gap*4;
	}

}
