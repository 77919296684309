// inject:css


// endinject
@import "https://unpkg.com/purecss@1.0.0/build/pure-min.css";
$fa-font-path: "../fonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
