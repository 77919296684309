.component-slider {
	position: relative;

	@include mediaquery(tablet-portrait) {
	}

	.slick-slide {
		width: 100vw;
		height: 320px;
		margin: 0 10px;

		@include mediaquery(tablet-portrait) {
			width: 511px;
		}

		div {
			height: 100%;
		}
	}

	.slider-item {
		position: relative;
		overflow: hidden;
		height: 0;
    	padding-bottom: 80%;

    	@include mediaquery(tablet-portrait) {
    		padding-bottom: 63%;
    	}

    	img {
    		position: absolute;
			top: 0;
			left: -50%;
			right: -50%;
			margin: 0 auto;
			height: 100%;
			width: auto;
    	}
	}

}