@import 'vendor';
@import 'slick.scss';

// // General variables and setup
@import 'vars';
@import 'reset';
@import 'mixins';
@import 'grid';

// // Global elements
@import 'global';
@import 'typography';
@import 'iconography';
@import 'theme';
@import 'padding';
@import 'forms';
@import 'footer';
@import 'nav';

// // Components
@import 'components/text';
@import 'components/gallery';
@import 'components/grid';
@import 'components/image';
@import 'components/availabilities';
@import 'components/slider';

// // Custom components
@import 'components/custom/map';

// // Routes
